import React from 'react';
import './style.scss';
import { useTranslation } from "react-i18next";
import Btn from '../Button';

const First = () => {
     const { t } = useTranslation();

    return (
        <div className='first' id='main'>
            {/* <div className='first__absolute' /> */}
             <h1><span>#RUSSIAN</span><span>SHAME</span><span>DAY</span></h1>
            <div className='container first__title'>
                <div className='first__header'>
                    <h2 className='first__header-title'>{t('firstTitle1')}</h2>
                </div>
                <p className='first__header-text'>{t('firstText1')}<span className='first__header-span'>{t('firstSpan')}</span>{t('firstText2')}</p>
                <Btn
                        theme='main'
                        title= {t('firstButton')}
                        url='https://drive.google.com/drive/u/0/folders/1zk027Dj3RC-XGLOSwo-u4HblFSnz4D6T'
                />
            </div>
        </div>
    )
}

export default First;