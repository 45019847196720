import React,{useState,useEffect,useCallback} from 'react';
import './style.scss';
import { useTranslation } from "react-i18next";
import Btn from '../Button';

const History = () => {
    const { t } = useTranslation();
        const[width,setWidth] = useState(window.innerWidth)
    const getWidth = useCallback(
        event => setWidth(window.innerWidth)
    ,[])

    useEffect(() => {
         window.addEventListener('resize', getWidth);
        return ( ()=> window.removeEventListener('resize',getWidth))
    },[getWidth])

    return (
        <div className='container history' id='history'>

            <div className='history__content'>
                <h2><span>{t('historyTitle1')}</span> <span>{t('historyTitle2')}</span></h2>
                {(width>767 && width<1240)?
                <div className='history__img'><img src='/images/uwc1.png' alt='ukrainian world congress' />
                <img src='/images/uwc2.png' alt='ukrainian world congress' /></div>:null}
                <p>{t('historyContent')}</p>
                <div className='history__btn'>
                    <Btn
                        theme='second'
                        title={t('historyBtn')}
                        url='https://www.ukrainianworldcongress.org'
                    />
                    <Btn
                        small
                        theme='second'
                        title=''
                        img='/images/facebook.svg'
                        url='https://www.facebook.com/UkrainianWorldCongress/'
                    />
                    <Btn
                        small
                        theme='second'
                        title=''
                        img='/images/twitter.svg'
                        url='https://twitter.com/UWCongress'

                    />
                    <Btn
                        small
                        theme='second'
                        title=''
                        img='/images/youtube.svg'
                        url='https://www.youtube.com/channel/UCr8arzeEhJx7rZQhBpijqHQ'
                    />
                </div>
            </div>
            <div className='history__img'>
                {(width>767 && width<1240)?null:<img src='/images/uwc.png' alt='ukrainian world congress' />}
            </div>
        </div>
    )
}

export default History;
