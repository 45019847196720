import React from 'react';
import './style.scss';
import { useTranslation } from "react-i18next";

const About = () => {
    const { t } = useTranslation();

    return (
        <div className='container about-wrap' id='about'>
            <div className='about'>
                
                <div className='about__part'>
                <h3 className='about__header'>{t('aboutTitle1')}</h3>
                <div className='about__text1'>{t('aboutMessage1')}<span className='first__header-span'>{t('aboutMessage2')}</span> <span>{t('aboutMessage3')}</span> <p className='about__span'>{t('aboutMessage4')}</p></div>
                {/* <p className='about__text2'>{t('aboutMessage2')}</p> */}
                <div className='about__cross'></div>
                {/* </div>
                <div className='about__part-right'> */}
                    <div className='about__cross-second'></div>
                    {/* <h3 className='about__header'>{t('aboutTitle2')}</h3> */}
                    <div className='about__cross-third'></div>
                    <p className='about__text2'>{t('aboutMessage5')}</p>
                </div>
            </div>
        </div>
    )
}

export default About;