const uk = {
    "navMain": "ГОЛОВНА",
    "navAbout": "ПРО ПРОЕКТ",
    "navHowItWork": "ДЕ/ЯК",
    "navask": "ЦІЛЬ",
    "navhistory": "ПРО СКУ",
    "navPartners": "ПАРТНЕРЫ",
    "navDonate": "ДОЛУЧИТИСЯ",
    "navStory": "ИСТОРИЯ",
    "firstTitle1": "ВИМАГАЄМО СПРАВЕДЛИВОСТІ",
    "firstText1": "Закликаємо напередодні ",
    "firstSpan": "9 травня, Дня російської ганьби",
    "firstText2": ", виходити на вулиці з мирними акціями, поширювати свідчення людей, які пережили російські звірства та звертатися до урядів і політичних лідерів світу визнати російські воєнні злочини геноцидом проти українського народу. ",
    "firstButton": "ЗАВАНТАЖИТИ МАТЕРІАЛИ",
    "askTitle":"ЩО ТРЕБА ЗРОБИТИ?",
    "askText0": "СВІТОВИЙ КОНҐРЕС УКРАЇНЦІВ",
    "askText1": " закликає прихильників України з усього світу напередодні  “Russian Shame Day”, 9 травня, звернутися до союзників України та урядів і політичних лідерів усіх миролюбних країн світу, з метою щоб:",
    "askText2": "Визнати російські військові злочини та звірства в Україні геноцидом українського народу",
    "askText3": "Визнати росію державою-спонсором тероризму / терористичною державою",
    "askText4": "Визнати Групу Вагнера міжнародною терористичною організацією",
    "askText5": "Створити спеціальний Міжнародний трибунал з військових злочинів Росії, який би розслідував злочини Російської Федерації, її керівництва, а також усіх винних у вчиненні військових злочинів та злочинів проти людяності в Україні.",
    "askText6": "СКУ просить усіх прихильників України, від звичайних користувачів соцмереж до представників міжнародних організацій, впливових інфлюенсерів та медіа-персон, підтримати кампанію, поширюючи інформацію про “Russian Shame Day” в соціальних мережах росії в соціальних мережах одним простим повідомленням:",
    "askLastText": "Вимагаємо справедливості!",
    "askBtn": "ПРЕС-РЕЛІЗ",
    "aboutTitle1": "Російський День перемоги — одне з головних свят у росії, що знаменує її тріумф у Другій світовій війні пафосною демонстрацією грубої військової сили.",
    "aboutMessage1": "Щороку 9 травня російські війська проходять парадом Червоною площею в патріотичному угарі з показом військового арсеналу, зокрема міжконтинентальних балістичних ракет, і Путіним у центрі всіх святкувань. У багатьох країнах світу проходять ",
    "aboutMessage2": "пропагандистські марші",
    "aboutMessage3": "“Безсмертний полк”.",
    "aboutMessage4": "В такий спосіб росіяни використовують історію як зброю.",
    "aboutMessage5": "У своїй політиці на окупованих українських територіях росіяни повторюють найгірші практики нацизму – масові розстріли і знущання над мирним населенням, умисне плюндрування та розкрадання приватної власності та цінностей, масові депортації цивільного населення. Війна Москви проти України є яскравим проявом новітнього імперіалізму і неоколоніалізму.",
    "aboutTitle2": "Росія вже зробила сумнівні заяви про перемогу в деяких частинах України, прагнучи підігнати уявний успіх до святкувань свого Дня перемоги.",
    "wherehowTitle1": "ДЕ?",
    "wherehowTitle2": "ЯК?",
    "wherehowText1": "Просимо розміщувати в соціальних мережах фотодокази руйнувань в Україні з хештегом ",
    "wherehowText2": ". Ці зображення можуть включати ваші власні світлини або фотографії з:",
    "wherehowText3": "Ви можете звернутися до представників влади з офіційним листом, провести мирну демонстрацію, ходу або ж автопробіг, використовуючи ключові тези та візуальні матеріали, підготовані СКУ. У соціальних мережах ми просимо поширювати свідчення жертв російського режиму з",
    "wherehowText4": ", аби соціальні мережі заполонив один простий меседж:",
    "wherehowBtn1": "ОТРИМАТИ МАТЕРІАЛИ",
    "wherehowBtn2": "ОТРИМАТИ МАТЕРІАЛИ",
    "historyTitle1": "ПРО ",
    "historyTitle2": "СВІТОВИЙ КОНҐРЕС УКРАЇНЦІВ",
    "historyContent": "Понад 55 років СКУ є голосом світового українства. Представляючи інтереси 20 мільйонів Українців, що живуть у більш як 60 країнах світу, СКУ активно підтримує права людини та демократію в Україні. СКУ визнана Економічною і соціальною радою ООН як неурядова організація зі спеціальним консультативним статусом та має партисипативний статус як міжнародна неурядова організація при Раді Європи.",
    "historyBtn": "НАШ САЙТ",
    "donateTitle": "ЗРОБІТЬ ПОЖЕРТВУ",
    "donateContent1": "СКУ збирає кошти на забезпечення захисників України нелетальним військовим спорядженням у рамках ініціативи #UniteWithUkraine. Наразі зібрано понад ",
    "donateContent2": ", які були використані для забезпечення наступних позицій:",
    "donateContentBetween": "40 мільйонів доларів США",
    "donateBtn": "ПІДТРИМАТИ",
    "supportTitle": "История 'Друга'",
    "supportBtn": "Donate",
    "donateText1": "джгутів та ізраїльських бинтів",
    "donateText2": "одиниць медичного обладнання",
    "donateText3": "бронежилетів",
    "donateText4": "броньованих шоломів",
    "donateText5": "засобів зв`язку",
    "donateText6": "протигазів",
    "donateText7": "IFAKS та травматологічних наборів",
    "donateText8": "приладів нічного бачення та тепловізорів",
    "donateText9": "дронів",
    "donateText10": "безпілотний літальний апарат",
    "donateCardHead": "ЗРОБІТЬ ВНЕСОК У ДОПОМОГУ"
};

export default uk
