import React,{useState,useCallback,useEffect} from 'react';
import './style.scss';
import { useTranslation } from "react-i18next";
import Btn from '../Button';
import {useLocation} from 'react-router-dom';


const HowWhere = () => {
    const { t } = useTranslation();
    let pathName = useLocation().pathname;
    const[width,setWidth] = useState(window.innerWidth)
    const getWidth = useCallback(
        event => setWidth(window.innerWidth)
    ,[])

    useEffect(() => {
         window.addEventListener('resize', getWidth);
        return ( ()=> window.removeEventListener('resize',getWidth))
    },[getWidth])


    return (
        <>
        {(width>767 && width<1240)?
        <div className='howwhere' id='howwhere'>
            <div className='container howwhere__block' id='howwhere'>
            <div className='howwhere__card'>
                <h2 className='howwhere__header'>{t('wherehowTitle1')}</h2>
                <p>{t('wherehowText1')}<span className='first__header-span'>#RussianShameDay</span>{t('wherehowText2')}</p>
                <div className='howwhere__btn'>
                    <Btn
                        theme='main'
                        title={t('wherehowBtn1')}
                        url={(pathName==='/')?'https://drive.google.com/file/d/1DqI28VTtmUGVu5rOj2QXP2prTDYD5YIu/view?usp=sharing':'https://drive.google.com/file/d/1DqI28VTtmUGVu5rOj2QXP2prTDYD5YIu/view?usp=sharing'}
                    />
                </div>
            </div>
            <div className='howwhere__card'>
                <h2 className='howwhere__header'>{t('wherehowTitle2')}</h2>
                <p>{t('wherehowText3')}<span className='first__header-span'>#RussianShameDay</span>{t('wherehowText4')}</p>
                <div className='howwhere__btn'>
                    <Btn
                    full
                        theme='main'
                        title={t('wherehowBtn2')}
                        url={(pathName==='/')?'https://drive.google.com/file/d/1gdIxIW8YEDJuh1pVkuppjM0OZtgNBowT/view?usp=sharing':'https://drive.google.com/file/d/1gdIxIW8YEDJuh1pVkuppjM0OZtgNBowT/view?usp=sharing'}
                    />
                </div>
            </div>
        </div>
        </div>:
        <div className='container howwhere' id='howwhere'>
            {/* <div className='howwhere__card'>
                <h2 className='howwhere__header'>{t('wherehowTitle1')}</h2>
                <p>{t('wherehowText1')}<span className='first__header-span'>#RussianShameDay</span>{t('wherehowText2')}</p>
                <div className='howwhere__btn'>
                    <Btn
                        theme='main'
                        title={t('wherehowBtn1')}
                        url={(pathName==='/')?'https://drive.google.com/file/d/1DqI28VTtmUGVu5rOj2QXP2prTDYD5YIu/view?usp=sharing':'https://drive.google.com/file/d/1DqI28VTtmUGVu5rOj2QXP2prTDYD5YIu/view?usp=sharing'}
                    />
                </div>
            </div> */}
            <div className='howwhere__card-img'>
                {width>1240?<img src='/images/howhere.png' alt='howwhere' />:<img src='/images/howheremob.png' alt='howwhere' />}
            </div>
            <div className='howwhere__card'>
                <h2 className='howwhere__header'>{t('wherehowTitle2')}</h2>
                <p>{t('wherehowText3')}<span className='first__header-span'>#RussianShameDay</span></p>
                <div className='howwhere__btn'>
                    <Btn
                        theme='main'
                        title={t('wherehowBtn2')}
                        url={(pathName==='/')?'https://drive.google.com/drive/u/0/folders/1zk027Dj3RC-XGLOSwo-u4HblFSnz4D6T':'https://drive.google.com/drive/u/0/folders/1zk027Dj3RC-XGLOSwo-u4HblFSnz4D6T'}
                    />
                </div>
            </div>
        </div>}
        </>
    )
}

export default HowWhere;
