import React from 'react';
import './style.scss';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='container footer__main'>
            <div className='footer__group'>
            <div className='bot_footer'>
                <img src='/images/logo.svg' alt='logo' />
            </div>
            <div className='footer__nav'>
                <p>145 Evans Avenue, Suite 207, Toronto, ON M8Z 5X8, Canada</p>
                <a className='footer-links' href='mailto:uwc@ukrainianworldcongress.org'>uwc@ukrainianworldcongress.org</a>
                <a className='footer-links' href='tel:+1 416 323-3020'>+1 416 323-3020</a>
            </div>
            </div>
            <div className='footer__nav-second'>
                
                <div className='footer__block'>
                    <div className='footer__block-title'>MEDIA CONTACTS</div>
                    <p>For media inquiries please contact:</p>
                </div>
                <div className='footer__block'>
                <div>
                    <p>Olha Domanska, UWC Communications coordinator</p>
                    <a className='footer-links'  href='mailto:olha.domanska@ukrainianworldcongress.org'>olha.domanska@ukrainianworldcongress.org</a>
                    <a className='footer-links'  href='tel:+380977782818'>+38 097 778 28 18</a>
                </div>
                <div>
                    <p>24/7</p>
                    <a className='footer-links' href='mailto:irene.uwc@ukrainianworldcongress.org'>uwc@ukrainianworldcongress.org</a>
                    <a className='footer-links' href='https://www.facebook.com/UkrainianWorldCongress/'>FB: @uwcongress</a>
                </div>
                </div>
            </div>
            </div>
        </footer>
    )
}

export default Footer;