import React from 'react';
import './style.scss';
import Navigation from '../../components/Navigation';
import i18n from "i18next";

const Header = ({mobile}) => {

    return (
        <header className={ 'header header-transparent'}>
            <div className={'header-container'}>
            {/* <div className='header__logo-container'>
                <div>#RUSSIAN</div>
                <div>SHAME</div>
                <div>DAY</div>
            </div> */}
                    <div>
                        <Navigation
                         //mobileNav={mobileNav}
                        //  onClose = {closeMobileNav}
                         />
                    </div>
                    {/* <div className={mobileNav?'header__mobile-btn': 'header__mobile-hidden'}>
                        <Btn
                        theme='main'
                        url='https://google.com'
                        title='GET MATERIALS'/>
                    <Btn
                        theme='main'
                        url='https://google.com'
                        title='DONATE & SUPPORT'/>
                    </div> */}
                    <div className='header__lang'>
                        <a
                        className={`header__lang-item${i18n.language==='uk' ?'-active':''}`}
                        href={'/uk/'}
                        >UA</a>/
                        <a
                        className={`header__lang-item${i18n.language==='en' ?'-active':''}`}
                        href={'/'}
                       >EN</a>
                    </div>
            </div>
            {/* <div className={"hamburger-menu header__visible"}>
                <input id={"menu__toggle"} type="checkbox" onClick={activateMobileNav} />
                <label className={"menu__btn"} htmlFor={"menu__toggle"}>
                    <span></span>
                </label>
            </div> */}
        </header>
    )
}

export default Header;
