import React,{useState,useCallback,useEffect} from 'react';
import './style.scss';
import Btn from '../Button';

import { useTranslation } from "react-i18next";

const Donate = () => {
    const { t } = useTranslation();
    const[width,setWidth] = useState(window.innerWidth)
    const getWidth = useCallback(
        event => setWidth(window.innerWidth)
    ,[])

    useEffect(() => {
         window.addEventListener('resize', getWidth);
        return ( ()=> window.removeEventListener('resize',getWidth))
    },[getWidth])

    const data = [
        {
            'img': '/images/donat1.png',
            'number': '15 600',
            //'text': `${t('donateText1')}`
            'text': 'Sets of Body Armor '
        },
        {
            'img': '/images/donat2.png',
            'number': '9 100',
            //'text': `${t('donateText2')}`
            'text': 'Ballistic Helmets'
        },
        {
            'img': '/images/donat3.png',
            'number': '2 000',
            //'text': `${t('donateText3')}`
            'text': 'Gas Masks '
        },
        {
            'img': '/images/donat4.png',
            'number': '10 580',
            //'text': `${t('donateText4')}`
            'text': 'Thermal Winter Base Layers'
        },
        {
            'img': '/images/donat5.png',
            'number': '8 000',
            //'text': `${t('donateText5')}`
            'text': 'Shovels'
        },
        {
            'img': '/images/donat6.png',
            'number': '25',
           // 'text': `${t('donateText6')}`
           'text': 'Armoured evacuation vehicles'
        },
        {
            'img': '/images/donat7.png',
            'number': '204',
            //'text': `${t('donateText7')}`
            'text': 'Thermal Sleep Systems'
        },
        {
            'img': '/images/donat8.png',
            'number': '1 510',
            //'text': `${t('donateText8')}`
            'text': 'Hypothermia Prevention Systems'
        },
        {
            'img': '/images/donat9.png',
            'number': '12',
            //'text': `${t('donateText9')}`
            'text': 'Battery Generator Portable Power Stations '
        },
        {
            'img': '/images/donat10.png',
            'number': '8 378',
            //'text': `${t('donateText10')}`
            'text': 'Individual First Aid Kits (IFAKs)'
        },
        {
            'img': '/images/donat11.png',
            'number': '42 320',
            //'text': `${t('donateText10')}`
            'text': 'Tourniquets '
        },
        {
            'img': '/images/donat12.png',
            'number': '100 000',
            //'text': `${t('donateText10')}`
            'text': 'Israeli Battle Bandages'
        },
        {
            'img': '/images/donat13.png',
            'number': '700',
            //'text': `${t('donateText10')}`
            'text': 'Combat Paramedic Trauma Packs '
        },
        {
            'img': '/images/donat14.png',
            'number': '10 500',
            //'text': `${t('donateText10')}`
            'text': 'Nasopharyngeal Airway Kits'
        },
        {
            'img': '/images/donat15.png',
            'number': '1 000',
            //'text': `${t('donateText10')}`
            'text': 'Occlusive Chest Seal Dressings: '
        },
        {
            'img': '/images/donat16.png',
            'number': '10',
            //'text': `${t('donateText10')}`
            'text': 'Oxygen Generator Field Portable Systems '
        },
        {
            'img': '/images/donat17.png',
            'number': '2 000',
            //'text': `${t('donateText10')}`
            'text': 'Wood Burning Stoves'
        },
        {
            'img': '/images/donat18.png',
            'number': '40 Tons',
            //'text': `${t('donateText10')}`
            'text': 'Civilian Humanitarian Aid'
        },
        {
            'img': '/images/donat19.png',
            'number': '21 276',
            //'text': `${t('donateText10')}`
            'text': 'Thermal Winter Tactical Boots'
        },
        {
            'img': '/images/donat20.png',
            'number': '63',
            //'text': `${t('donateText10')}`
            'text': 'Night Vision Monoculars '
        },
        {
            'img': '/images/donat21.png',
            'number': '138',
            //'text': `${t('donateText10')}`
            'text': 'Unmanned Aerial Vehicles/Drones '
        },
        {
            'img': '/images/donat22.png',
            'number': '27',
            //'text': `${t('donateText10')}`
            'text': '4x4 Vehicles'
        },
        {
            'img': '/images/donat23.png',
            'number': '4 397',
            //'text': `${t('donateText10')}`
            'text': 'UHF/VHF Radios & Other Communications Equipment '
        },
        {
            'img': '/images/donat24.png',
            'number': '100 000',
            //'text': `${t('donateText10')}`
            'text': 'Litres of Fuel'
        },
        {
            'img': '/images/donat25.png',
            'number': '558',
            //'text': `${t('donateText10')}`
            'text': '3kW — 8 kW Diesel Generators'
        },
        {
            'img': '/images/donat26.png',
            'number': '14',
            //'text': `${t('donateText10')}`
            'text': '150 Kw Diesel Generators'
        },
        {
            'img': '/images/donat27.png',
            'number': '50',
            //'text': `${t('donateText10')}`
            'text': 'Repaired trucks'
        },
        {
            'img': '/images/donat28.png',
            'number': '1',
            //'text': `${t('donateText10')}`
            'text': '500 Kw Diesel Generators '
        },
        {
            'img': '/images/donat29.png',
            'number': '15',
            //'text': `${t('donateText10')}`
            'text': 'JCB Excavators'
        },
        {
            'title':`${t('donateCardHead')}`
        }
    ]

    return (
        <>{(width>767 && width<1024)?
        <div className='donate' id='donate'>
            <div className='donate__background'>
            <div className='donate__content container'>
                <div className='donate__content-text'>
                    <h2>{t('donateTitle')}</h2>
                    <p>{t('donateContent')}</p>
                    <div>
                        <Btn
                            theme='main'
                            title={t('donateBtn')}
                            url='https://www.unitewithukraine.com/'
                        />
                    </div>
                </div>
            </div>
            </div>
            <div className='donate__list container'>
                {data.map((item,i)=>{
                    return(
                        item.title?
                        <div className='donate__list-bigitem' key={i}>
                            <h2 className='donate__list-title'>{item.title}</h2>
                            <div>
                                <Btn
                                    theme='main'
                                    title={t('donateBtn')}
                                    url='https://www.unitewithukraine.com/'
                                />
                            </div>
                        </div>
                        :
                        <div className='donate__list-item' key={i}>
                            <div className='donate__list-img'><img src={item.img} alt=''/></div>
                            <div className='donate__list-number'>{item.number}</div>
                            <div className='donate__list-text'>{item.text}</div>
                        </div>
                    )
                })}
            </div>

        </div>
        :
        <div className='container donate' id='donate'>
            <div className='donate__content'>
                <div className='donate__content-text'>
                    <h2>{t('donateTitle')}</h2>
                    <p>{t('donateContent1')} <b>{t('donateContentBetween')}</b>{t('donateContent2')}</p>
                    <div>
                        <Btn
                            theme='main'
                            title={t('donateBtn')}
                            url='https://www.unitewithukraine.com/'
                        />
                    </div>

                </div>
                <div className='donate__content-img'>
                    <img src='/images/donate.png' alt='donate' />
                </div>

            </div>

            <div className='donate__list'>
                {data.map((item,i)=>{
                    return(
                        item.title?
                        <div className='donate__list-bigitem' key={i}>
                            <h2 className='donate__list-title'>{item.title}</h2>
                            <div>
                                <Btn
                                    theme='main'
                                    title={t('donateBtn')}
                                    url='https://www.unitewithukraine.com/'
                                />
                            </div>
                        </div>
                        :
                        <div className='donate__list-item' key={i}>
                            <div className='donate__list-img'><img src={item.img} alt=''/></div>
                            <div className='donate__list-number'>{item.number}</div>
                            <div className='donate__list-text'>{item.text}</div>
                        </div>
                    )
                })}
            </div>
        </div>

        }

        </>
    )
}

export default Donate;
