import React from 'react';
import './style.scss';
import { useTranslation } from "react-i18next";
import Btn from '../Button';
import {useLocation} from 'react-router-dom';

const Ask = () => {
    const { t } = useTranslation();
    let pathName = useLocation().pathname;
    return (
        <div className='ask' id='ask'>
            <h2 className='ask__header container'>{t('askTitle')}</h2>

            <div className='ask__wrap container'>
                
                    <p className='ask__content'>{t('askText0')} {t('askText1')}</p>
                    <ul>
                        <li>{t('askText2')}</li>
                        <li>{t('askText3')}</li>
                        <li>{t('askText4')}</li>
                        <li>{t('askText5')}</li>
                    </ul>
                    <p className='ask__wrap-p'>{t('askText6')}</p>
                    <div className='ask__content-bottom'>
                        <p>{t('askLastText')}</p>
                        <div className='ask__content-block'>#RUSSIANSHAMEDAY</div>
                    </div>
                
                <div className='ask__image'></div>
            </div>

                    <div className='ask__btn container'>
                        <Btn
                            theme='main'
                            title={t('askBtn')}
                            url={(pathName === '/')?'https://docs.google.com/document/d/1XTC5-anrXAnITwGQhrfwEw49QqLZ0KJk2RDYeegmEfA/edit?usp=sharing':'https://docs.google.com/document/d/1XTC5-anrXAnITwGQhrfwEw49QqLZ0KJk2RDYeegmEfA/edit?usp=sharing'}
                        />
                    </div>
        </div>
    )
};

export default Ask;