import React from 'react';
import First from '../../components/First';
import Ask from '../../components/Ask';
import About from '../../components/About';
import HowWhere from '../../components/HowWhere';
import History from '../../components/History';
import Donate from '../../components/Donate';

const Content = () => {

    return (
        <section>
            <First />
            <About />
            <Ask />
            <HowWhere />
            <Donate />
            <History />            
        </section>
    )
}

export default Content;