import React,{Suspense} from 'react';
import Main from './layout/Main'


function App() {
  return (
    <Suspense fallback={<div className='loading'>Loading...</div>}>
      <Main />
    </Suspense>
  );
}

export default App;
