const en = {
    "navMain": "MAIN",
    "navAbout": "ABOUT PROJECT",
    "navHowItWork": "WHERE/HOW",
    "navask": "THE ASK",
    "navhistory":"ABOUT UWC",
    "navDonate": "DONATE",
    "navEdvisers": "EDVISERS",
    "navStory": "HISTORY",
    "firstTitle1": "WE DEMAND JUSTICE",
    "firstText1": "With ",
    "firstSpan": "May 9th - Russian Shame Day - approaching",
    "firstText2": ", we call on the civilized world to rally in the name of justice, share true Russian terrorism victims’ testimonies, and appeal to political leaders to recognize Russian war crimes as genocide against the Ukrainian people.",
    "firstButton": "GET MATERIALS",
    "askTitle": "WHAT NEEDS TO BE DONE?",
    "askText0": "The Ukrainian World Congress ",
    "askText1": "calls upon supporters of Ukraine from around the world on the eve of “Russian Shame Day“, May 9, to appeal to Ukraine's allies and the governments and political leaders of all peace-loving countries of the world to With “Russian Shame Day“ approaching on May 9th, the Ukrainian World Congress calls upon all Ukrainians and friends of Ukraine to appeal to the governments and political leaders of peace-loving countries around the world to:",
    "askText2": "Declare the Russian war crimes and atrocities in Ukraine as genocide of the Ukrainian people; ",
    "askText3": "Designate Russia as a state sponsor of terrorism/terrorist state;",
    "askText4": "Designate the Wagner Group as an international terrorist organization;",
    "askText5": "Establish a special International War Crimes Tribunal for Russia to investigate all of the Russian Federation’s crimes, including those of its leadership and those responsible for committing war crimes and crimes against humanity in Ukraine.",
    "askText6": "The UWC asks all supporters of Ukraine – from ordinary social media users to representatives of international organizations to influential influencers and media personalities – to support the #RussianShameDay campaign by sharing one simple message on social media:",
    "askLastText": "We demand justice!",
    "askBtn": "PRESS RELEASE",
    "aboutTitle1": "Russia’s ‘Victory Day’ on May 09 is a major annual holiday in Russia that commemorates not the Soviet Union’s – but Russia’s World War II triumph with a patriotic display of raw military power. ",
    "aboutMessage1": "Each year on Victory Day, Russian troops parade through Moscow's Red Square in a patriotic frenzy, with a display of military arsenal, including intercontinental ballistic missiles, and President Vladimir Putin at the center of the festivities. Many countries hold.",
    "aboutMessage2": "propagandist street demonstrations of the Russian",
    "aboutMessage3": " “Bezsmertnyi Polk” (“Immortal Regiment”)",
    "aboutMessage4": "This is just one way that Russians are weaponizing history.",
    "aboutMessage5": "In their policies in the occupied Ukrainian territories, Russians are repeating the worst practices of Nazism: mass shootings and abuse of civilians, deliberate destruction and theft of private property and valuables, destruction of churches and other religious sites, destruction of cultural heritage and mass deportations of civilians. Moscow's war against Ukraine vividly manifests modern imperialism and neocolonialism.",
    "aboutTitle2": "​​Russia has already made dubious claims of success in parts of Ukraine as it seeks to claim a presumptive victory in alignment with its Victory Day celebrations. ",
    "wherehowTitle1": "WHERE",
    "wherehowTitle2": "HOW",
    "wherehowText1": "We ask you to post on your social media feeds photo proof of the destruction in Ukraine with the hashtag ",
    "wherehowText2": ". These images could include personal photos or pictures taken",
    "wherehowText3": "You can address local authorities with an official letter, organize peaceful demonstrations, march or rally using key messages and visual materials the UWC has prepared for you. We ask you to share the Russian regime victims’ testimonies with ",
    "wherehowText4": " to your elected representatives.",
    "wherehowBtn1": "GET MATERIALS",
    "wherehowBtn2": "DOWNLOAD MATERIALS",
    "historyTitle1": "ABOUT THE",
    "historyTitle2": "UKRAINIAN WORLD CONGRESS",
    "historyContent": "Over the past 55 years, UWC has been the voice of the global Ukrainian community. Representing the interests of 20 million Ukrainians living more than 60 countries around the world, the UWC has been actively supporting human rights and democracy in Ukraine. UWC is recognized by the United Nations Economic and Social Council as a non-governmental organization with special consultative status and has a participatory status as an international non-governmental organization with the Council of Europe.",
    "historyBtn": "VISIT SITE",
    "donateTitle": "DONATE",
    "donateContent": "The UWC has been raising funds to supply the defenders of Ukraine with non-lethal protective equipment through their #UniteWithUkraine campaign. So far they have raised over US$ 21 million which has been used to deliver: ",
    "donateBtn": "DONATE & SUPPORT",
    "donateContent1": "The UWC has been raising funds to supply the defenders of Ukraine with non-lethal protective equipment through their #UniteWithUkraine initiative. To date, over ",
    "donateContent2": " has been raised and was used to deliver:",
    "donateContentBetween": "USD 40 million",
    "donateText1": "TOURNIQUETS & ISRAELI BANDAGES",
    "donateText2": "MEDICAL EQUIPMENT",
    "donateText3": "BODY ARMOR",
    "donateText4": "ARMORED HELMETS",
    "donateText5": " COMMUNICATIONS EQUIPMENT",
    "donateText6": "GAS MASKS",
    "donateText7": "IFAKS & MEDIC TRAUMA PACKS",
    "donateText8": "NIGHT VISION & THERMAL VISION",
    "donateText9": "DRONES",
    "donateText10": "UNMANNED AERIAL VEHICLE",
    "donateCardHead": "CONTRIBUTE TO HELP",
    "supportTitle": "Support Ukraine",
    "supportBtn": "Donate"
};

export default en;
