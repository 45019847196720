import React from 'react';
import {HashLink} from 'react-router-hash-link';
import './style.scss';
import { useTranslation } from "react-i18next";
import {useLocation} from 'react-router-dom';

const Navigation = ({footer,mobileNav,onClose}) => {
    let pathName = useLocation().pathname;
    const { t } = useTranslation();
    let num = -110;
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = num; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return (
        <nav className={footer?'navigation':!mobileNav?'navigation navigation__header': 'navigation navigation__header-mobile'}>
            <HashLink to ={`${pathName}#main`} 
                smooth 
                onClick = {onClose}
                scroll={el => scrollWithOffset(el)} 
                activeclassname = 'selected' 
                className = {mobileNav?'navigation__link-mobile':'navigation__link'}>{t('navMain')}</HashLink>
            <HashLink to ={`${pathName}#about`} 
                smooth 
                onClick = {onClose}
                scroll={el => scrollWithOffset(el)} 
                activeclassname = 'selected'  
                className = {mobileNav?'navigation__link-mobile':'navigation__link'}>{t('navAbout')}</HashLink>
            <HashLink to ={`${pathName}#ask` }
                smooth 
                activeclassname = 'selected' 
                onClick = {onClose}
                scroll={el => scrollWithOffset(el)}  
                className = {mobileNav?'navigation__link-mobile':'navigation__link'}>{t('navask')}</HashLink>
            <HashLink to ={`${pathName}#howwhere`} 
                smooth 
                onClick = {onClose}
                scroll={el => scrollWithOffset(el)} 
                activeclassname = 'selected'  
                className = {mobileNav?'navigation__link-mobile':'navigation__link'}>{t('navHowItWork')}</HashLink>
            <HashLink to ={`${pathName}#donate`} 
                smooth 
                onClick = {onClose}
                scroll={el => scrollWithOffset(el)} 
                activeclassname = 'selected'  
                className = {mobileNav?'navigation__link-mobile':'navigation__link'}>{t('navDonate')}</HashLink>
            <HashLink to ={`${pathName}#history`}
                smooth 
                onClick = {onClose}
                scroll={el => scrollWithOffset(el)} 
                activeclassname = 'selected'  
                className = {mobileNav?'navigation__link-mobile':'navigation__link'}>{t('navhistory')}</HashLink>
        </nav>
    )
}

export default Navigation;