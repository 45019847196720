export const languageCode = () => {
    const pathname = window.location.pathname;
    const isEn = pathname.indexOf('/uk') !== -1 ? 'uk' : 'en';

    switch (isEn) {
        case 'uk':
            return {
                code: 'uk_UA',
                name: 'uk',
                forUrl:'/uk/'
            }
        default:
            return {
            code: 'en_US',
            name: 'en',
            forUrl:'/'
        }
    }

}
